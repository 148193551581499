import React, {lazy, Suspense, useContext} from 'react';
import { graphql } from 'gatsby';
import Hero from '../../../modules/hero/hero';
import HtmlBlock from '../../../components/htmlBlock/htmlBlock';
import ModuleWrapper from '../../../globals/moduleWrapper/moduleWrapper';
import { LangContext } from '../../../context/LocaleContext';
import { archiveBasic, devices, archiveBasic__intro, archiveBasic__outro } from './archiveBasic.module.scss';

const ContentBlock = lazy(() => import('../../../components/contentBlock/contentBlock'));
const Cta = lazy(() => import('../../../modules/cta/cta'));

const ArchiveBasic = ({data}) => {
  const { getTranslation } = useContext(LangContext);

  const {datoCmsPageArchiveBasic, allDatoCmsPosttypeIndustry, allDatoCmsPosttypeProduct } = data || {};
  const { title, conclusionCta, hero, introCopy, slug, slugCopy1 ,redirect} = datoCmsPageArchiveBasic;
  let postArray = allDatoCmsPosttypeProduct?.nodes.length > 0 ? allDatoCmsPosttypeProduct.nodes : (allDatoCmsPosttypeIndustry?.nodes || {});

  return (
  <div className={`${archiveBasic} ${slug === 'devices' ? devices : ''}`}>
    {
      hero &&  <ModuleWrapper border="Lifted Bottom" container={false}><Hero className="" data={hero[0]} headingTag={'h2'} eyebrow={<HtmlBlock tag="h1">{title}</HtmlBlock>} /></ModuleWrapper>
    }
    <Suspense fallback="">
      <div className="container">
        {
          introCopy && <ContentBlock className={archiveBasic__intro} data={introCopy[0]}/>
        }
      </div>
        {
          postArray.length > 0 && postArray.map((post, i)=>{
            let image;

            if((post.template && post.template[0])){
              image = post.template[0].featuredImage;
            }else if(post.hero && post.hero.length > 0){
              image = post.hero[0].backgroundImage;
            }

            let ctaObject = {
              'colorProfile': 'Default',
              'ctaContent': [{
                'heading': post.title,
                'content': post.seo?.description || '',
                'linkS' : [{ 'displayText' : getTranslation('GoToPage'), 'slug': post.redirect ? post.slugCopy1 : post.slug }]
              }],
              'ctaType': 'Secondary Framed Image',
              'image': image || post.seo?.image,
              'textPosition': 'Right',
            }
            
            return <Cta key={i} data={ctaObject}/>
            }
          )
        }
        <div className="container">
        {
          conclusionCta && <ContentBlock className={archiveBasic__outro} linkclass="btn--primary" data={conclusionCta[0]}/>
        }
      </div>
      <ModuleWrapper border="Lifted Top"></ModuleWrapper>
    </Suspense>
  </div>
  )
}

export default ArchiveBasic


export const query = graphql`
  query($id: String, $locale : String!, $postType : String) {
    datoCmsPageArchiveBasic(originalId : {eq : $id}, locale: {eq: $locale}){
      conclusionCta{
        ...CopyLinkFragment
      }
      hero{
        ...HeroFragment
      }
      introCopy{
        ...CopyLinkFragment
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      title
    }

    allDatoCmsPosttypeIndustry(filter: {model: { apiKey: {eq: $postType  }}}){
      nodes{
        id
        hero{
          backgroundImage{
            ...HalfImageFragment
          }
        }
        seo{
          description
        }
        slug
        title
      }
    }

    allDatoCmsPosttypeProduct(filter: {locale : {eq: $locale}, category: {archivePage : {originalId : {eq: $id}}}, model: { apiKey: {eq: $postType  }} }){
      nodes {
        id
        slug
        slugCopy1
        redirect
        seo{
          description
          image{
            gatsbyImageData(width: 730)
            url
          }
        }
        template{
          ... on DatoCmsTemplateApplication{
            featuredImage{
              ...HalfImageFragment
            }
          }
        }
        title
      }
    }
  }
`

