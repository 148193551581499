// extracted by mini-css-extract-plugin
export var absoluteSubmit = "archiveBasic-module--absoluteSubmit--2XY72";
export var actions = "archiveBasic-module--actions--ye3PW";
export var archiveBasic = "archiveBasic-module--archive--basic--9nC7V";
export var archiveBasic__intro = "archiveBasic-module--archive--basic__intro--BC-di";
export var archiveBasic__outro = "archiveBasic-module--archive--basic__outro--gzRxY";
export var chevron = "archiveBasic-module--chevron--XZbNN";
export var devices = "archiveBasic-module--devices--Fjj7h";
export var focusVisible = "archiveBasic-module--focus-visible--b2YTX";
export var hsErrorMsg = "archiveBasic-module--hs-error-msg--87uO8";
export var hs_submit = "archiveBasic-module--hs_submit--NngeN";
export var icon = "archiveBasic-module--icon--bKotq";
export var input = "archiveBasic-module--input--4cBiR";