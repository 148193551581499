import React from 'react';
import { generateSlug } from '../../lib/functions/util-functions';
import PropTypes from 'prop-types';

/**
 *
 * Wrapper for all modules
 */

const ModuleWrapper = ({anchor, border, children, className, container, ...other}) => {
  return (
    <section id={anchor || ''} className={`${className || ''} ${border && border !== 'None' ? generateSlug(border) : ''}`} {...other}>
      {
        border === 'Gradient Border Top' && <hr />
      }
      <div className="section__wrap">
      {
        container ?
        <div className="container">
          {children}
        </div>
        :
        <>
          {children}
        </>
      } 
      </div>
      {
        border === 'Gradient Border Bottom' && <hr/>
      }
    </section>
  );
};

ModuleWrapper.propTypes = {
  /**
  * Anchor id from cms
  */
  anchor: PropTypes.string,
  /**
  * border type from cms
  */
  border: PropTypes.oneOf(['', 'None', 'Gradient Border Bottom', 'Gradient Border Top', 'Lifted Top', 'Lifted Bottom', 'Lifted Fully']),

  /**
  * Module content
  */
  children: PropTypes.any,
  /**
  * Module className
  */
  className: PropTypes.string,
  /**
  * If module requires a container
  */
  container: PropTypes.bool
};

ModuleWrapper.defaultProps = {
  container : true
};

export default ModuleWrapper;
